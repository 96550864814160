import { ImageContentBoxSection } from './contentTypes';
import { ImageContentBox } from './ImageContentBox';

export interface ImageContentProps extends ImageContentBoxSection {}

export const ImageContent = ({ copy, img }: ImageContentProps) => {
  return (
    <div className="ImageContent">
      <div className="bg-white">
        <div className="container-fluid container-fluid-w-custom ">
          <ImageContentBox copy={copy} img={img} />
        </div>
      </div>
    </div>
  );
};
